export default function Header() {
  return (
    <header>
      <div className="h-[52px] px-1.5 py-0.5 shadow-[0_4px_5px_rgba(0,0,0,0.05)] flex items-center justify-between">
        <a href="https://limo.media" className="flex gap-2 items-center">
          <img src="/static/img/logo-limo.svg" alt="LIMO" className="h-3" />
          <span className="text-xs text-[#333333] hidden md:inline">
            くらしとお金の経済メディア
          </span>
        </a>
        <a
          href="https://limo.media/category/reader-present-news"
          className="w-[150px] h-full text-[10px] bg-[#FF4B00] flex items-center justify-center text-white rounded-md"
        >
          <picture className="w-[150px] h-[40px]">
            <source
              srcSet="/static/img/present-for-subscriber-button.webp 1x, /static/img/present-for-subscriber-button@2x.webp 2x"
              type="image/webp"
            />
            <source
              srcSet="/static/img/present-for-subscriber-button.png 1x, /static/img/present-for-subscriber-button@2x.png 2x"
              type="image/png"
            />
            <img
              src="/static/img/present-for-subscriber-button.png"
              alt="読者プレゼントキャンペーン一覧"
            />
          </picture>
        </a>
      </div>
    </header>
  );
}
